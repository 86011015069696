import { styled, Button, Typography, Rating } from "@mui/material";

export const StyledTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Google Sans,Roboto,Arial,sans-serif",
  color: "#999fa5",
}));
export const StyledButton = styled(Button)(({ theme }) => ({
  fontFamily: "Google Sans,Roboto,Arial,sans-serif",
  textTransform: "capitalize",
  color: "#999fa5",
  borderColor: "#999fa5",
  "&:hover": {
    borderColor: "#999fa5",
    backgroundColor: "#f7f8f8 !important",
  },
}));
export const StyledRating = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#01875f",
  },
});
