import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "techcom-landing-v1",
  storage,
  // whitelist: ["auth", "menu"],
};

const persist = (reducers) => persistReducer(persistConfig, reducers);

export default persist;
