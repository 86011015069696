import { lazy } from "react";

// project imports
import Layout from "../layouts";
import Loadable from "../components/Loadable";

const Home = Loadable(lazy(() => import("../pages/Home")));
const Upload = Loadable(lazy(() => import("../pages/Upload")));

const MainRoutes = {
  path: "/",
  element: <Layout />,
  children: [
    {
      path: "/store/apps/details",
      element: <Home />,
    },
    {
      path: "/upload",
      element: <Upload />,
    },
  ],
};

export default MainRoutes;
