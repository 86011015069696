import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import { Outlet } from "react-router-dom";

const Wrapper = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box
      sx={{
        marginTop: isMobile ? 7 : 8,
        minHeight: "calc(100vh - 64px - 48px)",
        // padding: (theme) => theme.spacing(4, 0)
      }}
    >
      <Outlet />
    </Box>
  );
};
export default Wrapper;
