import { BrowserRouter } from "react-router-dom";

import Routes from "./routes";
import Snackbar from "./components/base/snackbar";

function App() {
  return (
    <BrowserRouter basename="">
      <Routes />
      <Snackbar />
    </BrowserRouter>
  );
}

export default App;
