import { Box, Container, Grid } from "@mui/material";
import { StyledTypography } from "./styledComponents";

// import LogoSection from './MainLayout/LogoSection';

const Footer = () => {
  return (
    <Container>
      <Box
        sx={{
          mt: 2,
          "@media (max-width:767px)": {
            marginBottom: "70px",
          },
          pt: "20px",
          borderTop: "solid 1px #999fa5",
        }}
      >
        <Grid
          container
          columnSpacing={{ xs: 5, sm: 10, md: 20 }}
          sx={{ mb: 3 }}
          rowSpacing={2}
        >
          <Grid item sm={12} md={12}>
            <Grid
              container
              columnSpacing={{ xs: 3, sm: 5, md: 10 }}
              rowSpacing={2}
            >
              <Grid
                item
                sm={12}
                md={4}
                display={"flex"}
                flexDirection={"column"}
                gap={1}
              >
                <StyledTypography
                  color={"#999fa5"}
                  sx={{ cursor: "pointer" }}
                  fontWeight={"bold"}
                >
                  Google Play
                </StyledTypography>
                <StyledTypography color={"#999fa5"} sx={{ cursor: "pointer" }}>
                  Play Pass
                </StyledTypography>
                <StyledTypography color={"#999fa5"} sx={{ cursor: "pointer" }}>
                  Play Points
                </StyledTypography>
                <StyledTypography color={"#999fa5"} sx={{ cursor: "pointer" }}>
                  Gift cards
                </StyledTypography>
                <StyledTypography color={"#999fa5"} sx={{ cursor: "pointer" }}>
                  Redeem
                </StyledTypography>
                <StyledTypography color={"#999fa5"} sx={{ cursor: "pointer" }}>
                  Refund policy
                </StyledTypography>
              </Grid>
              <Grid
                item
                sm={12}
                md={4}
                display={"flex"}
                flexDirection={"column"}
                gap={1}
              >
                <StyledTypography
                  color={"#999fa5"}
                  sx={{ cursor: "pointer" }}
                  fontWeight={"bold"}
                >
                  Children and family
                </StyledTypography>
                <StyledTypography color={"#999fa5"} sx={{ cursor: "pointer" }}>
                  Parent guide
                </StyledTypography>
                <StyledTypography color={"#999fa5"} sx={{ cursor: "pointer" }}>
                  Family sharing
                </StyledTypography>
              </Grid>
              <Grid item md={6} sm={12} display={"flex"} gap={2}>
                <StyledTypography
                  fontSize={".75rem"}
                  color={"#999fa5"}
                  sx={{ cursor: "pointer" }}
                >
                  Terms of service
                </StyledTypography>
                <StyledTypography
                  fontSize={".75rem"}
                  color={"#999fa5"}
                  sx={{ cursor: "pointer" }}
                >
                  Privacy
                </StyledTypography>
                <StyledTypography
                  fontSize={".75rem"}
                  color={"#999fa5"}
                  sx={{ cursor: "pointer" }}
                >
                  About Google Play
                </StyledTypography>
                <StyledTypography
                  fontSize={".75rem"}
                  color={"#999fa5"}
                  sx={{ cursor: "pointer" }}
                >
                  Developers
                </StyledTypography>
                <StyledTypography
                  fontSize={".75rem"}
                  color={"#999fa5"}
                  sx={{ cursor: "pointer" }}
                >
                  Google Store
                </StyledTypography>
              </Grid>
              <Grid
                item
                md={6}
                sm={12}
                display={"flex"}
                justifyContent={"flex-end"}
              >
                <StyledTypography color={"#999fa5"} fontSize={".75rem"}>
                  Hong Kong (English (United Kingdom))
                </StyledTypography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default Footer;
