import App from "./App";
import "./assets/css/index.css";
// import "../assets/css/index.css";

import { Root, MuiThemeProvider, ConfigProvider } from "./providers";
import { store } from "./store";
import { Provider } from "react-redux";
Root.render(
  <ConfigProvider>
    <MuiThemeProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </MuiThemeProvider>
  </ConfigProvider>
);
