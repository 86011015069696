import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import { CssBaseline, styled, Tab, Tabs, useMediaQuery } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";

import Logo from "./base/logo";
import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom";
const pages = ["Games", "Apps", "Movies", "Books", "Children"];

function ResponsiveAppBar() {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      textTransform: "none",
      fontFamily: "Google Sans,Roboto,Arial,sans-serif",
      marginRight: theme.spacing(1),
      color: "#5f6368",
      "&.Mui-selected": {
        color: "#00a173",
      },
      "&.Mui-focusVisible": {
        backgroundColor: "rgba(100, 95, 228, 0.32)",
      },
    })
  );
  const StyledTabs = styled((props) => (
    <Tabs
      {...props}
      TabIndicatorProps={{
        children: <span className="MuiTabs-indicatorSpan" />,
      }}
    />
  ))({
    "& .MuiTabs-indicator": {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "transparent",
    },
    "& .MuiTabs-indicatorSpan": {
      maxWidth: 40,
      width: "100%",
      backgroundColor: "#00a173",
    },
  });

  const [activePage, setActivePage] = React.useState("Apps");

  const handleChange = (event, newValue) => {
    setActivePage(newValue);
    if (newValue === "upload") {
      navigate("upload");
    }
    if (newValue === "Apps") {
      navigate("main");
    }
  };
  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="fixed" color="inherit">
        <Toolbar sx = {{justifyContent:"space-between"}}>
          <Box sx={{ width: "50px", height: "50px" }}>
            <Logo />
          </Box>
          &nbsp;
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/main"
            sx={{
              mr: 2,
              fontFamily: "monospace",
              fontWeight: 700,
              color: "#5f6368",
              textDecoration: "none",
              letterSpacing: "-1px",
              display: isMobile ? "none" : "flex",
            }}
          >
            Google&nbsp;Play
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              height: "64px",
              display: { xs: "none", md: "flex" },
            }}
          >
            <StyledTabs
              sx={{
                "& .MuiTabs-flexContainer": {
                  height: "100%",
                },
              }}
              value={activePage}
              onChange={handleChange}
            >
              {pages.map((page, i) => (
                <StyledTab value={page} key={i} label={page} />
              ))}
              <StyledTab value={"upload"} label={"Upload"} />
            </StyledTabs>
          </Box>
          <Box sx={{ display: "flex", flexGrow: 0, gap: "5px" }}>
            <IconButton size={"small"}>
              <SearchIcon />
            </IconButton>
            <IconButton size={"small"}>
              <HelpOutlineRoundedIcon />
            </IconButton>
            <IconButton size="small" sx={{ p: 0 }}>
              <Avatar
                alt="Remy Sharp"
                sx={{ borderRadius: "50%", width: "30px", height: "30px" }}
              />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}
export default ResponsiveAppBar;
